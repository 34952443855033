import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import TwitterLogo from '../../images/twitter-logo.svg'
import YoutubeLogo from '../../images/youtube-logo.svg'
import TelegramLogo from '../../images/telegram-logo.svg'
import RedditLogo from '../../images/reddit-logo.svg'
import MediumLogo from '../../images/medium-logo.svg'
import LinkedinLogo from '../../images/linkedin-logo.svg'
import DiscordLogo from '../../images/discord-logo.svg'
import GithubLogo from '../../images/github-logo.png'

const FutureSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 200px;
  max-width: 1100px;
  text-align: center;
  margin: 100px auto 0;
  
  @media screen and (max-width: 880px) {
    > div {
      max-width: 480px;
      margin: 0 auto;
    }
  }
`

const Content = styled.div`
  width: 100%;
  padding: 25px;
  padding-bottom: 5px;
  max-width: 800px;
  margin: 10px auto 0;
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 40px);
    padding: 25px 20px 5px;
  }
`

const SocialsWrapper = styled.div`
  width: fit-content;
  margin: 20px auto 0;
  
  img {
    width: 50px;
    filter: invert(1);
    margin: 5px 15px;
    
    :hover {
      opacity: 0.8;
    }
  }
  
  @media screen and (max-width: 880px) {
    width: 300px;
  }
`

const StyledSubtitle = styled(Subtitle)`
  font-weight: 200;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 30px;
`

// markup
const FutureSection = () => {

  return (
    <FutureSectionWrapper>
      <div>
        <Content>
          <Title style={{ fontSize: '36px', marginBottom: '25px' }}>
            This is only the beginning
          </Title>
          <StyledSubtitle>
            The Dolomite community is constantly improving the exchange and building out exciting new features to make Dolomite even more powerful for traders. Dolomite was founded by folks with years of experience trading in the crypto space who aim to build the exchange they have always dreamt of using.<br /><br />Join our community of traders and builders to help shape the future of Dolomite!
          </StyledSubtitle>
        </Content>
        <SocialsWrapper>
          <a href={'https://www.twitter.com/Dolomite_io'} target={'_blank'}><img src={TwitterLogo} alt={'twitter logo'} /></a>
          {/*<a href={'https://www.reddit.com/r/Dolomite'} target={'_blank'}><img src={RedditLogo} alt={'reddit logo'} /></a>*/}
          <a href={'https://medium.com/dolomite-official'} target={'_blank'}><img src={MediumLogo} alt={'medium logo'} /></a>
          <a href={'https://t.me/dolomite_official'} target={'_blank'}><img src={TelegramLogo} alt={'telegram logo'} /></a>
          <a href={'https://discord.gg/uDRzrB2YgP'} target={'_blank'}><img src={DiscordLogo} alt={'discord logo'} /></a>
          <a href={'https://github.com/dolomite-exchange'} target={'_blank'}><img src={GithubLogo} alt={'GitHub logo'} /></a>
          {/*<a href={'https://dolomite.io'} target={'_blank'}><img src={YoutubeLogo} alt={'youtube logo'} /></a>
          <a href={'https://dolomite.io'} target={'_blank'}><img src={LinkedinLogo} alt={'linkedin logo'} /></a>*/}
        </SocialsWrapper>
      </div>
    </FutureSectionWrapper>
  )
}

export default FutureSection
